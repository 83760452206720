<template>
  <v-container
    :key="load"
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser"
    >
    <Header 
      title="Configuración general"
      />
    <v-tabs
      v-model="tab"
      >
      <v-tab>General</v-tab>
      <v-tab
        >Personalizar</v-tab>
    </v-tabs>
    <Edit 
      :user="currentUser"
      v-if="tab == 0"
      />
    <Customize
      v-else
      :user="currentUser"
      />
  </v-container>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import { mapGetters } from 'vuex'
import { SignIn } from '@/graphql/mutations/users'

const Edit = () => import(/* webpackPrefetch: true */ "@/components/admin/settings/Edit");
const Customize = () => import(/* webpackPrefetch: true */ "@/components/admin/settings/Customize");
const Header = () => import(/* webpackPrefetch: true */ "@/components/admin/shared/Header");

export default {
  data () {
    return {
      tab: 0,
      load: 0
    }
  },

  components: {
    Edit,
    Header,
    Customize,
  },

  created () {
    this.signIn()
    Vue.use(VueGoogleMaps, {
      load: {
        key: "AIzaSyB3RTiKtL9Wx4PT1gwp9FsRGsDu9qJittc",
        libraries: "places", // necessary for places input
        region: process.env.VUE_APP_LOCATION,
        language: "es"
      }
    })
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  methods: {
    signIn () {
      this.$apollo.mutate({
        mutation: SignIn,
        variables: {
          input: {
            token: localStorage.getItem('auth_token')
          }
        },
        fetchPolicy: 'no-cache'
      }).then(res => {
        this.load += 1
        let data = res.data.signIn

        if (data.token) {
          localStorage.setItem('auth_token', data.token)
          localStorage.setItem('prefetched_sub', data.user.selectedProfile.id)
          this.$store.commit("setCurrentUser", data.user)
        } else {
          localStorage.removeItem('auth_token')
        }
      }).catch(errors => {
        localStorage.removeItem('auth_token')
        this.$router.go()
      })
    }
  }
}
</script>

<style scoped>
>>>.v-slide-group__content {
  background: #ebeef3;
}
</style>
